<script setup>
import { ref, toRef, nextTick, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import Header from '~/components/Header.vue'
import ErrorModal from '~/components/ErrorModal.vue'
import ConfirmModal from '~/components/ConfirmModal.vue'
import Notifications from '~/components/Notifications.vue'
import Loader from '~/components/Loader.vue'
import SocketConnectionStatus from '~/components/SocketConnectionStatus.vue'
import InterfaceBlocker from "~/components/InterfaceBlocker.vue"
import ProcessIsUnderway from "~/components/ProcessIsUnderway.vue"
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import '~/assets/scss/common.scss'

const store = useStore()
const route = useRoute()
const config = useRuntimeConfig()

const routeParams  = computed(() => route.params)
const routePath = computed(() => route.path.split('/'))
const cssFileName = computed(() => {
    if (routePath.value.includes('objects')) return 'objects'
    else if (routePath.value.includes('layers')) return 'layers'
    else if (routePath.value.includes('engineering')) return 'engineering'
    else if (routePath.value.includes('economics')) return 'economics'
    else if (routePath.value.includes('taxes')) return 'taxes'
    else return 'base'
})

useHead({
    link: [
        { 
            rel: 'stylesheet', 
            href: () => import(`~/assets/scss/${cssFileName.value}.scss`)
        }
    ]
})

const getProjectData = async () => {
    if (!route.params.project_id || route.params.project_id === 'new') return

    const { data, pending, error, refresh } = await useFetch(`/api/projects/${route.params.project_id}`)

    if (error.value) {
        const errorMessage = error.value.data ? error.value.data : error.value.statusMessage

        showError({
            statusCode: 404,
            statusMessage: errorMessage.message
        })

        store.commit('common/setLoadingStatus', false)

        return
    }

    store.commit('common/setProjectOptions', { name: data.value.name, id: data.value.id })
}

function checkSuggests() {
    setTimeout(function checkSuggest() {
        const suggests = document.querySelectorAll(".js-show-suggest")
        const contentWrap = document.querySelector(".content")

        if (!contentWrap) return
        const contentRect = contentWrap.getBoundingClientRect()

        if (suggests.length) {
            Array.prototype.forEach.call(suggests, (suggest) => {
                if (suggest.dataset.listener !== "true") {
                    suggest.dataset.listener = "true"
                    suggest.addEventListener("mouseover", (e) => {
                        const content = suggest.querySelector(".suggest__content")

                        if (content == null) return

                        if (content) {
                            content.classList.add("active")
                            suggest.classList.add("active")
                            suggest.parentElement.classList.add("show-suggest")
                        }

                        const coords = content.getBoundingClientRect()
                        const scrollParentRect = getScrollParentRect(suggest)

                        if (scrollParentRect) {
                            if (coords.left + coords.width > scrollParentRect.width) {
                                content.classList.add("left")
                            }
                        }

                        if (coords.left + coords.width > contentRect.width) {
                            content.classList.add("left")
                        } else if (coords.top < contentRect.top) {
                            content.classList.add("bottom")
                        }

                    });
                    suggest.addEventListener("mouseout", () => {
                        const content =
                            suggest.querySelector(".suggest__content");
                        if (content == null) return;
                        if (content) {
                            content.classList.remove("active");
                            suggest.classList.remove("active");
                            suggest.parentElement.classList.remove(
                                "show-suggest"
                            );
                        }
                    });
                }
            });
        }

        setTimeout(checkSuggest, 1000);
    }, 1000);
}

function getScrollParentRect(node) {
    if (node == null) {
        return null;
    }

    if (node.scrollHeight > node.clientHeight) {
        return node.getBoundingClientRect();
    } else {
        return getScrollParentRect(node.parentNode);
    }
}

watch(routeParams, (params, oldVal) => {
    if (!params) return
    if (params.project_id) getProjectData()
}, { deep: true })

onMounted(() => {
    getProjectData()
    checkSuggests()
})

const setWindowEcho = () => {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        wsHost: config.public.SOCKET_URL,
        wsPort: config.public.SOCKET_WS_PORT,
        wssHost: config.public.SOCKET_URL,
        wssPort: config.public.SOCKET_WSS_PORT,
        key: config.public.SOCKET_KEY,
        cluster: config.public.SOCKET_CLUSTER,
        forceTLS: config.public.PROTOCOL === 'https' ? true : false,
        disableStats: true,
        enabledTransports: ['ws', 'wss']
    })
}

let echoIntervalID = ref(null)
let echoStatus = ref(null)
let echoAvaliable = ref(false)
let echoStatusKey = ref(0)

window.Pusher = Pusher
setWindowEcho()

echoIntervalID.value = setInterval(() => {
    setWindowEcho()
    echoStatus.value = 'WebSocket connecting'
    echoAvaliable.value = false
    echoStatusKey.value += 1
}, 1000)

window.Echo.connector.pusher.connection.bind('connected', function(event) {
    clearInterval(echoIntervalID.value)
    echoStatus.value = 'Success: WebSocket connection successfull'
    echoAvaliable.value = true
    echoStatusKey.value += 1
})
window.Echo.connector.pusher.connection.bind('error', function(event) {
    echoStatus.value = 'WebSocket connecting'
    echoAvaliable.value = false
    echoStatusKey.value += 1
    echoIntervalID.value = setInterval(() => {
        setWindowEcho()
    }, 1000)
})
window.Echo.connector.pusher.connection.bind('unavailable', function(event) {
    clearInterval(echoIntervalID.value)
    echoStatus.value = 'Error: WebSocket connection failed'
    echoAvaliable.value = false
    echoStatusKey.value += 1
})

watch(echoStatusKey, (value) => {
    store.commit("common/setSockedConnectionStatus", {
        status: echoStatus.value,
        avaliable: echoAvaliable.value,
    })
})
</script>

<template>
    <div>
        <component :is="'style'">
            :root {
                --grey-color: #F7F7F9;
                --blue-color: #117EC2;
                --text-color: #14223D;
                --text-grey-color: #92A2C0;
            }
        </component>
        <component :is="'style'" v-if="routePath.includes('objects')">
            :root {
                 --primary-color: #117EC2;
                 --gradient-from-color: #E6F1F5;
                 --gradient-to-color: #C7EBFF;
                 --grey-color: #F7F7F9;
                 --blue-color: #117EC2;
                 --text-color: #14223D;
                 --text-grey-color: #92A2C0;
                 --text-danger-color: #DD303A;
                 --border-grey-color: #ECECED;
            }
        </component>
        <component :is="'style'" v-else-if="routePath.includes('layers')">
            :root {
                 --primary-color: #DD303A;
                 --text-grey-color: #92A2C0;
                 --border-grey-color: #d7d7d7;
            }
        </component>
        <component :is="'style'" v-else-if="routePath.includes('engineering')">
            :root {
                 --primary-color: #117EC2;
                 --gradient-from-color: #E6F1F5;
                 --gradient-to-color: #C7EBFF;
                 --grey-color: #F7F7F9;
                 --blue-color: #117EC2;
                 --text-color: #14223D;
                 --text-grey-color: #92A2C0;
                 --text-danger-color: #DD303A;
                 --border-grey-color: #ECECED;
            }
        </component>
        <component :is="'style'" v-else-if="routePath.includes('economics')">
            :root {
                 --primary-color: #00B031;
                 --gradient-from-color: #079F31;
                 --gradient-to-color: #7AEE97;
                 --gradient-2-from-color: #ACF1BD;
                 --gradient-2-to-color: #D5EEF1;
                 --border-grey-color: #EAEBED;
                 --text-grey-color: #7185AC;
            }
        </component>
        <component :is="'style'" v-else-if="routePath.includes('taxes')">
            :root {
                 --primary-color: #5636B2;
                 --gradient-from-color: #5636B2;
                 --gradient-to-color: #926FF9;
                 --gradient-2-from-color: #CFBEFF;
                 --gradient-2-to-color: #DED4FC;
                 --border-grey-color: #EAEBED;
                 --text-grey-color: #7185AC;
            }
        </component>
        <component :is="'style'" v-else>
            :root {
                 --primary-color: #FC934C;
                 --gradient-from-color: #F48032;
                 --gradient-to-color: #F7B189;
                 --gradient-2-from-color: #FFA996;
                 --gradient-2-to-color: #FFD3B6;
                 --border-grey-color: #EAEBED;
            }
        </component>
        <div class="w-100">
            <Header/>
            <div class="background">
                <div class="container">
                    <div>
                        <slot/>
                    </div>
                    <ErrorModal/>
                    <ConfirmModal/>
                    <Notifications/>
                    <SocketConnectionStatus/>
                    <Loader/>
                </div>
            </div>
        </div>
    </div>
    <InterfaceBlocker/>
    <ProcessIsUnderway/>
</template>